import { byClass, parent } from 'OK/utils/dom';
import { activate as activateAutofocus } from 'OK/autofocus';
import capture from 'OK/capture';

var CSS_ACTIVE = '__active';

export default class GoSearch {
    constructor() {
        this.options = {
            closable: '0', // если 1, то можно закрывать поиск
            resetActiveItems: '1', // если 0, то не будет сниматься класс __active у элементов фильтра
            autofocus: '',
            resetLoaderHook: false,
        };
        this._activeItems = [];
        this._toogle = this._toogle.bind(this);
        this._loaders = [];
    }

    _show() {
        if (this.options.resetActiveItems === '1') {

            var activeItems = this._activeItems;

            /* Снимаем "активность" фильтров */
            byClass(this._filter, 'filter_i').forEach(
                /** @param {HTMLElement} el */
                function (el) {
                    if (el.classList.contains(CSS_ACTIVE)) {
                        activeItems.push(el);
                        el.classList.remove(CSS_ACTIVE);
                    }
                }
            );
        }

        /* Делаем "активным" текущий фильтр */
        this._el.classList.add(CSS_ACTIVE);

        this._toogleNext();

        this._filter.classList.add('__search-visible');

        if (this.options.resetLoaderHook === 'true') {
            this._loaders.forEach(function (loader) {
                capture.deactivate(loader, true);
            });
        }
    }

    _toogleNext() {
        /* Пробегаемся по следующим элементам и модифицируем их видимость */
        var next = this._filter.nextElementSibling, autofocusEl = this.options.autofocus;
        while (next) {
            next.classList.toggle('invisible');
            next = next.nextElementSibling;
        }

        if (autofocusEl) {
            autofocusEl = this._filter.parentNode.querySelector(autofocusEl);
            if (autofocusEl) {
                activateAutofocus(autofocusEl);
            }
        }

    }

    _hide() {
        if (this.options.closable === '0') {
            return;
        }

        this._activeItems.forEach(
            /** @param {HTMLElement} el */
            function (el) {
                el.classList.add(CSS_ACTIVE);
            }
        );

        this._activeItems = [];

        this._el.classList.remove(CSS_ACTIVE);

        this._toogleNext();

        this._filter.classList.remove('__search-visible');

        if (this.options.resetLoaderHook === 'true') {
            this._loaders.forEach(function (loader) {
                capture.activate(loader);
            });
        }
    }

    _toogle(ev) {
        var el = this._el;

        ev.preventDefault();

        if (el.classList.contains(CSS_ACTIVE)) {
            this._hide();
        } else {
            this._show();
        }

    }

    _initOptions(options) {
        Object.assign(this.options, options);
    }

    _findLoaders() {
        var loaderNodes = this._filter.parentNode.querySelectorAll(".hookBlock .loader-container");
        this._loaders = Array.prototype.slice.call(loaderNodes);
    }

    _handleLinkClick(e) {
        const href = e.currentTarget.getAttribute('href');

        if (!window.navigateOnUrlFromJS) {
            window.location.href = href;
        } else {
            window.navigateOnUrlFromJS(href);
        }
        e.preventDefault();
    }

    /**
     * @param {HTMLElement} el
     */
    activate(el) {
        this._el = el;

        if (el.getAttribute('href')) {
            el.addEventListener('click', this._handleLinkClick);
            return;
        }

        this._initOptions(el.dataset);

        this._filter = parent(el, 'filter');

        if (!this._filter) {
            return;
        }

        el.addEventListener('click', this._toogle);

        if (this.options.resetLoaderHook === 'true') {
            this._findLoaders();
        }
    }

    deactivate() {
        this._el.removeEventListener('click', this._toogle);
        this._el.removeEventListener('click', this._handleLinkClick);
        OK.util.clean(this);
    }
}
